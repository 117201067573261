import type { v1, ExtractFromAPI } from "@netgame/openapi";
import { camelCase } from "scule";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";
import useGamesState from "./useGamesState";
import useGetGameFiltersData from "./useGetGameFiltersData";
import useLastPathPart from "./useLastPathPart";

type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
export type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
type Game = NonNullable<GamesPayload["games"]>[number];

const pickAndCompact = (
	games: Record<string | number, Game & { img: string }> | undefined,
	id: Array<string | number>
) => {
	if (!games) {
		return [];
	}
	return compact(Object.values(pick(games, id)));
};

const useGamesLoadMore = (gameType?: "favorites" | "recent", gameOfWeekId?: number) => {
	const limitPageName = ref();
	const menu = useState("issues-menu", () => [] as GamesPayload["menu"]);
	const banner = useState("issues-banner", () => [] as GamesPayload["gameOfWeek"]);
	const { all, add } = useGamesState();
	const page = useLastPathPart<"all-games">();
	const {
		params: { pageName }
	} = useRoute();
	const { isMobile } = useDevice();

	const { data: appInitData } = useAppInitData();
	const isLogin = computed(() => appInitData.value?.isGuest === false);

	const { data: allGamesData } = useAsyncFetch({
		path: `/rest/page/issues/${page as "all-games"}/`,
		method: "get",
		options: {
			default: () => ({
				payload: {
					games: Array.from({ length: 25 }).map(() => Infinity),
					menu: menu.value,
					banner: banner.value
				}
			}),
			transform(data) {
				return {
					...data,
					payload: {
						...data.payload,
						games: add(data?.payload?.games || [])
					}
				};
			}
		},
		fetchOptions: () => ({
			onResponse: ({ response }) => {
				if (response?._data?.status === 404) {
					navigateTo("/error");
				}
				menu.value = response._data.payload.menu || [];
				banner.value = response._data.payload.gameOfWeek || {};
			}
		})
	});

	const { games } = useGetGameFiltersData();
	const {
		public: { limit, loadMoreLimit, mobileLimit, mobileLoadMoreLimit }
	} = useRuntimeConfig();
	const deviceLimit = computed(() => {
		if (isMobile) {
			return mobileLimit;
		}
		return limit;
	});
	const deviceLoadMoreLimit = computed(() => {
		if (isMobile) {
			return mobileLoadMoreLimit;
		}
		return loadMoreLimit;
	});

	const allGames = computed(() =>
		allGamesData.value?.payload?.games?.map((id, index) => {
			if (id === Infinity) {
				return { id: index, skeleton: true };
			}
			return all.value![id];
		})
	);
	const seoInfo = computed(() => allGamesData.value.seo);

	const filteredGames = computed(() => games.value.reduce((acc, game) => ({ ...acc, [game.id]: game }), {}));
	const favorites = computed(() => pickAndCompact(filteredGames.value, appInitData.value?.favoriteGames || []));
	const recent = computed(() => pickAndCompact(filteredGames.value, appInitData.value?.lastGames || []));

	const gameData = computed(() => {
		if (gameType === "favorites") {
			limitPageName.value = "favorites";
			return favorites.value;
		}
		if (gameType === "recent") {
			limitPageName.value = "recent";
			return recent.value;
		}

		limitPageName.value = pageName;
		return allGames.value;
	});

	const gamesLimit = useState(camelCase(`${limitPageName.value}-limit`), () => deviceLimit.value);

	const slicedGames = computed(() => {
		if (!gameData.value || !gameData?.value?.length) {
			return [];
		}

		if (gameOfWeekId) {
			const indexGameOfWeek = gameData.value?.findIndex((game) => game?.id === gameOfWeekId);
			const limit =
				indexGameOfWeek >= 0 && indexGameOfWeek <= gamesLimit.value ? gamesLimit.value : gamesLimit.value - 1;
			return gameData.value.slice(0, limit);
		}

		return gameData.value.slice(0, gamesLimit.value);
	});

	const showLoadMoreButton = computed(() => {
		if (!gameData.value || !gameData.value?.length) {
			return false;
		}
		return gamesLimit.value < (gameData.value.length || deviceLimit.value);
	});

	const handleLoadMoreClick = () => {
		gamesLimit.value += deviceLoadMoreLimit.value;
	};

	onUnmounted(() => (gamesLimit.value = deviceLimit.value));

	return {
		seoInfo,
		isLogin,
		banner,
		menu,
		gamesLimit,
		slicedGames,
		showLoadMoreButton,
		handleLoadMoreClick
	};
};
export default useGamesLoadMore;
