<script setup lang="ts">
const { t } = useT();

const {
	slicedGames: recentSliced,
	showLoadMoreButton: showLoadMoreRecentButton,
	handleLoadMoreClick: handleLoadMoreRecentClick
} = useGamesLoadMore("recent");
</script>

<template>
	<div v-if="recentSliced.length" class="last-games">
		<div class="section-title">
			<ASvg name="24/title-star" class="icon icon-star" />
			<ASvg name="24/title-star-blue-blur" class="icon icon-star-blur" />
			<AText variant="tlaquepaque" :modifiers="['medium']">{{ t("Your") }}</AText>
			<AText
				variant="teresina"
				as="span"
				gradient="var(--ghaziabad)"
				:modifiers="['bold', 'uppercase']"
				class="text-tlalnepantla"
			>
				<span>{{ t("LAST GAMES") }}</span>
			</AText>
		</div>

		<OGames :games="recentSliced" />

		<div v-if="showLoadMoreRecentButton" class="button-load-more">
			<AButton variant="primary" class="btn" size="xl" @click="handleLoadMoreRecentClick">
				<AText variant="tanta" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ t("Load more") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style scoped lang="scss">
.last-games {
	margin: gutter(3) 0;

	@include media-breakpoint-down(md) {
		margin: gutter(2) 0 gutter(6);
	}

	&:deep(.grid-cards) {
		margin-top: gutter(3.5);
		padding-bottom: gutter(4);
	}
}
.section-title {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	gap: 0 gutter(2);
	position: relative;
	padding: 0 gutter(2);

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(2);
	}

	.icon-star {
		position: absolute;
		font-size: 32px;
		left: 9%;
		top: 10px;

		@include media-breakpoint-down(md) {
			font-size: 28px;
			top: -25px;
			left: 30px;
		}
	}
	.icon-star-blur {
		position: absolute;
		font-size: 76px;
		left: 3%;
		top: 20px;

		@include media-breakpoint-down(md) {
			left: -20px;
			top: -20px;
		}
	}
}
.favorite-empty {
	margin: gutter(7) auto gutter(4);
	width: 328px;
	text-align: center;
	h3 {
		margin: 0 auto;
	}

	.description {
		margin-top: gutter(2);
	}
}

.favorite-empty-img {
	max-width: 280px;
	height: auto;
}
.recent-title {
	margin: gutter(3.75) 0 gutter(3.125);
}
.button-load-more {
	width: calc(100% - 32px);
	display: flex;
	justify-content: center;
	margin: gutter(3) auto gutter(4);
	@include media-breakpoint-down(sm) {
		margin: gutter(3) auto gutter(1);
	}
	button {
		min-width: 280px;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}
</style>
